@import '../../styles/mixins/mobile';

.dashboard {
	$self: &;

	background-color: #fff;
	display: flex;
	overflow: hidden;

	@media (max-width: 991px) {
		min-height: 100vh;
		padding-bottom: 64px;
		padding-top: 48px;
	}

	@media (min-width: 992px) {
		height: 100vh;
	}

	.dropdown-user {
		position: fixed;
		right: 20px;
		top: 10px;

		@media (min-width: 992px) {
			position: absolute;
			top: 17px;
		}
	}

	&-header {
		display: flex;
		padding: 20px;

		@media (max-width: 991px) {
			@include header;

			align-items: center;
			background-color: #fff;
			box-shadow: 0 0 4px 1px #d5dfe8;
			justify-content: space-between;
			padding-right: 71px;
		}

		@media (min-width: 992px) {
			background-color: #f5f7fb;
			flex: 0 0 268px;
			flex-direction: column;
			width: 268px;

			.nav-item {
				margin-left: 0;
			}
		}

		.logo {
			display: block;

			@media (min-width: 992px) {
				margin-bottom: 40px;

				> img {
					height: 32px;
				}
			}
		}

		.navbar {
			flex-direction: column;
			flex-grow: 1;
			justify-content: space-between;

			&.show {
				display: flex;
			}

			@media (max-width: 991px) {
				background-color: #fff;
				border-top: 1px solid #d5dfe8;
				box-shadow: 0px 3px 12px -2px rgba(85, 102, 119, 0.25);
				bottom: 0;
				left: 0;
				font-size: 0;
				position: fixed;
				width: 100%;
			}

			@media (min-width: 992px) {
				display: flex;
			}
		}

		.nav {
			display: flex;
			flex-direction: column;

			@media (max-width: 991px) {
				flex-direction: row;
				gap: 8px;
				height: 64px;
				justify-content: space-around;
			}

			.icon {
				fill: #8c97a0;
				transition: fill 0.15s ease;
				vertical-align: bottom;

				@media (min-width: 992px) {
					margin-right: 8px;
				}
			}

			&-link {
				border-radius: 5px;
				color: #384756;
				display: block;
				font-weight: 600;
				transition: background-color 0.15s ease, color 0.15s ease;
				padding: 12px 14px;
				width: 100%;

				&.active{
					background-color: #d8e5ff;
					color: #1452ff;

					.icon {
						fill: currentColor;
					}
				}

				&:hover {
					color: #1452ff;

					.icon {
						fill: currentColor;
					}
				}
			}
		}
	}

	&-content {
		// display: flex;
		// flex-direction: column;
		// height: 100%;
		padding: 20px 20px 64px;
		width: 100%;

		@media (min-width: 992px) {
			padding-left: 32px;
			padding-right: 32px;
			padding-top: 28px;
		}
	}

	&-subheader {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;

		@media (min-width: 992px) {
			margin-bottom: 2.375rem;
		}

		#{$self}-opening {
			margin-bottom: 0;
		}
	}

	&-title {
		display: flex;
		align-items: baseline;
		column-gap: 16px;
		flex-wrap: wrap;
		row-gap: 8px;
		word-break: break-word;

		.badge {
			padding: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&-opening {
		color: #54626f;
		margin-bottom: 2rem;
	}

	&-filter {
		margin-bottom: 20px;

		@media (min-width: 992px) {
			margin-bottom: 3rem;
		}

		.form {
			&-group {
				display: flex;
				flex-direction: column;
				gap: 16px;

				@media (min-width: 576px) {
					flex-direction: row;
				}

				@media (min-width: 992px) {
					align-items: center;
				}

				> .btn {
					white-space: nowrap;

					@media (max-width: 575px) {
						width: 100%;
					}
				}
			}

			&-control {
				flex-grow: 1;
			}
		}

		.dropdown {
			@media (max-width: 575px) {
				width: 100%;
			}
		}

		.badge-list {
			&:before {
				color: #1c2c3c;
				content: 'Filtro';
				font-size: 14px;
				font-weight: 600;
				margin-right: 4px;
			}
		}
	}

	&-fixed-bottom {
		display: flex;
		justify-content: flex-end;

		@media (min-width: 992px) {
			.btn-scroll-top {
				display: none;
			}
		}

		@media (max-width: 991px) {
			bottom: 80px;
			left: 20px;
			position: fixed;
			right: 20px;
			z-index: 998;

			> * {
				margin-bottom: 0 !important;

				&:not(.btn-scroll-top) {
					width: 100%;
				}
			}

			.btn-scroll-top {
				display: none;
			}

			&.scroll-top {
				> * {
					display: none;
				}

				.btn-scroll-top {
					display: block;
				}
			}
		}
	}
}
