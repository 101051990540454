.alert {
    align-items: center;
    border-radius: 4px;
    display: flex;
    font-size: 13px;
    font-weight: normal;
    justify-content: space-between;
    line-height: 1.35;
    margin-bottom: 1rem;
    padding: 14px 15px;

    @media (min-width: 992px) {
        font-size: 14px;
        line-height: 1.5;
    }

    .icon {
        display: none;

        @media (min-width: 992px) {
            display: block;
            fill: currentColor;
            flex: 0 0 auto;
        }
    }

    .btn {
        display: none;

        @media (min-width: 992px) {
            background: transparent !important;
            border: none;
            display: block;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            padding: 0;
            text-transform: uppercase;

            &:hover {
                color: currentColor;
            }
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        word-break: break-word;

        @media (min-width: 992px) {
            margin-left: 1rem;
            margin-right: 1rem;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &-info {
        background-color: #D8E5FF;

        .icon,
        .btn {
            color: #1452FF;
        }
    }

    &-success {
        background-color: #EAF6F1;

        .icon,
        .btn {
            color: #00832D;
        }
    }

    &-warning {
        background-color: #FFF2CF;

        .icon,
        .btn {
            color: #FFBA00;
        }
    }

    &-danger {
        background-color: #FFEEED;

        .icon,
        .btn {
            color: #EA4335;
        }
    }
}