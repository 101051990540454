.tooltip {
    border-radius: 1px;
    color: #fff;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.35;
    position: absolute;
    z-index: 998;
    width: max-content;

    ul {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
    }

    li {
        display: flex;

        &:before {
            content: "-";
            margin-right: 4px;
        }
    }

    .icon-check_circle {
        fill: #00832D;
    }

    .icon-close {
        fill: #EA4335;
    }

    &-body {
        background-color: #001122;
        padding: 6px;
        text-align: center;
    }

    &:after {
        border: 6px solid transparent;
        content: "";
        position: absolute;
    }

    &.top,
    &.bottom {
        left: 50%;
        transform: translateX(-50%);
    }

    &.top {
        bottom: 100%;
        margin-bottom: 3px;
        padding-bottom: 6px;

        &:after {
            border-bottom: none;
            border-top-color: #001122;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &.bottom {
        margin-top: 3px;
        padding-top: 6px;
        top: 100%;

        &:after {
            border-top: none;
            border-bottom-color: #001122;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
        }
    }
}