.avatar {
    background-color: #1452FF;
    border-radius: 50%;
    border: 2px solid transparent;
    color: #fff;
    flex: 0 0 auto;
    font-size: 24px;
    font-weight: 600;
    height: 40px;
    line-height: 36px;
    overflow: hidden;
    text-align: center;
    text-transform: uppercase;
    width: 40px;

    > img {
        display: block;
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}