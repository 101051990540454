.empty-list {
    align-items: center;
    color: #54626F;
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    text-align: center;

    @media (min-width: 992px) {
        font-size: 18px;
        line-height: 1.35;
    }

    > .icon {
        color: #1452FF;
        margin-bottom: 16px;
    }

    .h1 {
        color: #3D464F;
    }

    .btn {
        margin-top: 10px;

        @media (max-width: 991px) {
            width: 100%;
        }

        @media (min-width: 992px) {
            margin-top: 24px;
        }
    }
}

.modal {
    .empty-list {
        > .icon {
            height: 64px;
            width: 64px;
        }

        .h1 {
            font-size: 20px;
        }
    }
}