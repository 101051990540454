.progress {
    display: block;
    height: 8px;
    background-color: #F5F7FB;
    border-radius: 16px;
    overflow: hidden;
    width: 100%;

    &-bar {
        background-color: #1452FF;
        color: #fff;
        text-align: center;
        font-size: 0;
        content: '';
        display: block;
        height: 100%;
    }
}