:root {
    --action-bg-color: transparent;
    --action-color: transparent;

    --bs-primary-rgb: 20, 82, 255;
    --bs-secondary-rgb: 112, 125, 136;
    --bs-info-rgb: 32, 114, 185;
    --bs-danger-rgb: 234, 67, 53;
    --bs-body-color-rgb: 28, 44, 60;
}

body {
    background-color: #F9F9F9;
    color: #1C2C3C;
}

#root {
    position: relative;
    min-height: -webkit-fill-available;
}

a {
    text-decoration: none;

    .icon {
        vertical-align: baseline;
    }

    &,
    &:hover {
        color: #1452FF;
    }
}

h1, h2, h3, h4, h5, h6 {
    color: #1C2C3C;
    margin-bottom: 1rem;
    word-break: break-word;
}

.h1, h1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.15;

    @media (min-width: 992px) {
        font-size: 24px;
    }
}

.h2, h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.15;

    @media (min-width: 992px) {
        font-size: 20px;
    }
}

.h3, h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.35;
    margin-bottom: 10px;

    @media (min-width: 992px) {
        font-size: 18px;
        margin-bottom: 1rem;
    }
}

.h4, h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.35;
}

.h5, h5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.35;
}

.display-1 {
    font-size: 48px;
    font-weight: 600;
    line-height: 1.66667;

    @media (min-width: 992px) {
        font-size: 64px;
        line-height: 1.125;
    }
}

.display-2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.25;

    @media (min-width: 992px) {
        font-size: 48px;
        font-weight: 600;
        line-height: 1.66667;
    }
}

.display-3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.33334;

    @media (min-width: 992px) {
        font-size: 32px;
        line-height: 1.25;
    }
}

b {
    font-weight: 500;
}

.small, small {
    font-size: 12px;
    line-height: 1.35;
}

hr {
    border-top-color: #D5DFE8;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    opacity: 1;
    
    @media (min-width: 992px) {
        margin-bottom: 56px;
        margin-top: 56px;
    }
}

#credential_picker_container {
    top: 72px !important;
}