.plan {
    &-item {
        background-color: #fff;
        border: 1px solid #E1E5EB;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 21px 20px 20px;
        
        .btn {
            padding: 0;
        }

        @media (min-width: 992px) {
            padding: 28px 24px 36px;
            text-align: center;
            width: 25%;
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        @media (min-width: 992px) {
            display: block;
            margin-bottom: 10px;
        }
    }

    &-body {
        flex-grow: 1;
    }

    &-title {
        color: #95AD09;
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;

        @media (max-width: 991px) {
            margin-bottom: 0;
        }
    }

    &-price {
        align-items: baseline;
        color: #142573;
        display: flex;
        font-size: 36px;
        font-weight: 700;
        line-height: 1.11112;

        > span {
            color: #707D88;
            font-size: 16px;
            line-height: 1.5;
            font-weight: normal;
            margin-left: 6px;
        }

        @media (min-width: 992px) {
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
        }
    }

    &-docs {
        &-total {
            align-items: center;
            color: #1C2C3C;
            display: flex;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.11112;
            margin-bottom: 4px;

            > span {
                color: #707D88;
                font-size: 13px;
                font-weight: normal;
                line-height: 1.4;
                margin-left: 4px;
            }

            @media (min-width: 992px) {
                justify-content: center;
                line-height: 1.55556;
                margin-bottom: 6px;
            }
        }

        &-extra {
            color: #707D88;
            font-size: 13px;
            line-height: 1.4;
        }
    }

    &-footer {
        margin-top: 24px;
    }
}

.plans {
    &-list {
        display: grid;
        gap: 24px;
        margin-bottom: 56px;

        @media (min-width: 992px) {
            display: flex;
            gap: 32px;
            margin-bottom: 64px;
        }
    }

    &-infos {
        .h4 {
            margin-bottom: 32px;
        }

        ul {
            color: #1C2C3C;
            display: grid;
            gap: 10px;
            list-style: none;
            margin-bottom: 0;
            padding-left: 0;
        }

        li {
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2313446F' viewBox='0 0 11 12'%3E%3Cpath d='M1.068 5.266c-.352-.242-.66-.242-.914.038a.582.582 0 0 0 .017.807l3.759 4.23c.321.326.642.48.857.484V7.73l-3.72-2.464ZM10.799 1.162a.605.605 0 0 0-.866.044L4.787 7.731v3.095c.679 0 1.062-.56 1.062-.56l5.044-8.29a.633.633 0 0 0-.094-.815Z'/%3E%3C/svg%3E") no-repeat 4px 6px;
            background-size: 13px 13px;
            padding-left: 28px;
            padding-right: 5px;
        }

        @media (min-width: 992px) {
            ul {
                display: flex;
                flex-wrap: wrap;
                gap: 0;
                row-gap: 12px;
            }

            li {
                width: 33.33334%;
            }
        }
    }
}