input {
	&[type='checkbox'],
	&[type='radio'] {
		appearance: none;
		background: #fff no-repeat center;
		border: 1px solid #d5dfe8;
		height: 18px;
		transition: all 0.15s ease;
		width: 18px;

		&:checked {
			background-color: #1452ff;
			border-color: #1452ff;
		}
	}

	&[type='checkbox'] {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 14 14'%3E%3Cpath d='M2.77 6.274c-.337-.227-.631-.227-.873.036a.541.541 0 0 0 .016.758L5.5 11.046c.307.307.614.451.819.455V8.59L2.77 6.274ZM12.058 2.415a.584.584 0 0 0-.827.041L6.32 8.593v2.91c.648 0 1.013-.528 1.013-.528l4.815-7.793a.589.589 0 0 0-.09-.767Z'/%3E%3C/svg%3E");
		background-size: 14px;
		border-radius: 3px;

		&:checked.has-checked {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 14 14'%3E%3Cpath d='M2.917 7.583V6.417h8.166v1.166H2.916Z'/%3E%3C/svg%3E");
		}
	}

	&[type='radio'] {
		border-radius: 50%;

		&:checked {
			background-color: #fff;
			border-width: 6px;
		}
	}

	&[type='search'] {
		@media (min-width: 992px) {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%238C97A0' viewBox='0 0 18 18'%3E%3Cpath d='m16.6 18-6.3-6.3A6.096 6.096 0 0 1 6.5 13c-1.817 0-3.354-.629-4.612-1.887C.628 9.854 0 8.317 0 6.5c0-1.817.629-3.354 1.888-4.613C3.146.629 4.683 0 6.5 0c1.816 0 3.354.629 4.613 1.887 1.258 1.259 1.886 2.796 1.886 4.613a6.096 6.096 0 0 1-1.3 3.8l6.3 6.3-1.4 1.4ZM6.5 11c1.25 0 2.312-.437 3.188-1.312.874-.875 1.311-1.938 1.311-3.188 0-1.25-.437-2.313-1.311-3.188C8.812 2.437 7.75 2 6.5 2c-1.25 0-2.313.437-3.188 1.312S2 5.25 2 6.5c0 1.25.437 2.313 1.312 3.188S5.25 11 6.5 11Z'/%3E%3C/svg%3E");
			background-repeat: no-repeat;
			background-size: 18px 18px;
			background-position: 15px center;
			padding-left: 46px;
		}
	}
}

.input {
	&-group {
		align-items: center;
		display: flex;
		flex-grow: 1;
		position: relative;

		@media (max-width: 575px) {
			width: 100%;
		}
	}

	&-variables {
		display: flex;
		align-items: center;
        gap: 12px;
        margin-top: 16px;

        .title{
            margin: 0;
            font-weight: 600;
            font-size: 14px;
            line-height: 1.5;
            color: #1C2C3C;
        }
	}
}

.list-variables{
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.item-variable {
	cursor: pointer;
	padding: 4px 9px;
	font-size: 12px;
    line-height: 1.3;
    color: #707D88;
	font-weight: 500;
}

.control-datepicker {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%238C97A0' viewBox='0 0 24 24'%3E%3Cpath d='M19.5 3h-2.25v-.75a.75.75 0 1 0-1.5 0V3h-7.5v-.75a.75.75 0 0 0-1.5 0V3H4.5A1.5 1.5 0 0 0 3 4.5v15A1.5 1.5 0 0 0 4.5 21h15a1.5 1.5 0 0 0 1.5-1.5v-15A1.5 1.5 0 0 0 19.5 3ZM9.75 17.625a2.27 2.27 0 0 1-1.594-.656.75.75 0 0 1 .243-1.225.74.74 0 0 1 .817.165.778.778 0 0 0 .534.216.75.75 0 1 0 0-1.5h-.234l-.038-.019H9.45l-.047-.018h-.01l-.056-.038H9.32l-.038-.028-.037-.028-.019-.019-.028-.028a.81.81 0 0 1-.122-.188.545.545 0 0 1-.056-.159c-.01-.01-.01-.019-.01-.037A.037.037 0 0 0 9 14.034v-.225c0-.018.01-.028.01-.037v-.038a.056.056 0 0 0 .009-.037c.01-.01.01-.019.01-.038l.018-.028c0-.018 0-.028.01-.037l.018-.038v-.028l.019-.037.019-.028.028-.038.018-.019.525-.656H8.625a.75.75 0 1 1 0-1.5h2.625a.75.75 0 0 1 .581 1.219l-.825 1.04a2.25 2.25 0 0 1-1.256 4.116Zm6-.75a.75.75 0 1 1-1.5 0V13.5l-.3.225a.76.76 0 0 1-1.05-.15.75.75 0 0 1 .15-1.05l1.5-1.125a.75.75 0 0 1 1.2.6v4.875ZM19.5 7.5h-15v-3h2.25v.75a.75.75 0 0 0 1.5 0V4.5h7.5v.75a.75.75 0 1 0 1.5 0V4.5h2.25v3Z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-size: 24px 24px;
	background-position: right 16px center;
	padding-right: 56px;
}

.react-datepicker {
	$datepicker: &;

	border: none;
	box-shadow: 0px 3px 12px -2px rgba(85, 102, 119, 0.12);
	display: flex;

	&__header {
		background-color: #1452ff;
	}

	&__current-month,
	&-time__header,
	&__day-name,
	&-year-header {
		color: #fff;
	}

	&__navigation {
		&:hover *::before {
			border-color: #fff;
		}

		&-icon {
			top: 5px;

			&::before {
				border-color: #fff;
			}
		}
	}

	&-popper {
		&[data-placement^='top'] {
			padding-bottom: 4px;
		}

		&[data-placement^='bottom'] {
			padding-top: 4px;
		}
	}

	&__triangle {
		display: none;
	}

	&__day--selected,
	&__day--in-selecting-range,
	&__day--in-range,
	&__month-text--selected,
	&__month-text--in-selecting-range,
	&__month-text--in-range,
	&__quarter-text--selected,
	&__quarter-text--in-selecting-range,
	&__quarter-text--in-range,
	&__year-text--selected,
	&__year-text--in-selecting-range,
	&__year-text--in-range {
		background-color: #1452ff;
	}

	&__time-container {
		border-left-color: #f5f7fb;
	}

	&__time-list-item--selected {
		background-color: #1452ff !important;
	}
}

.control-switch {
	$self: &;

	align-items: center;
	color: #1c2c3c;
	cursor: pointer;
	display: inline-flex;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: -0.154px;

	input {
		display: none;

		&:checked {
			~ #{$self} {
				&-label {
					&:before {
						content: attr(data-label-on);
					}
				}

				&-inner {
					background-color: #1452ff;

					#{$self}-bullet {
						left: calc(100% - 2px);
						transform: translateX(-100%);
					}
				}
			}
		}
	}

	&-label {
		margin-right: 12px;

		&:before {
			content: attr(data-label-off);
		}
	}

	&-inner {
		background-color: #d5dfe8;
		border-radius: 12px;
		display: block;
		height: 24px;
		padding: 2px;
		position: relative;
		transition: background 0.15s ease;
		width: 40px;
	}

	&-bullet {
		background-color: #fff;
		border-radius: 50%;
		display: block;
		height: 20px;
		left: 2px;
		position: absolute;
		top: 2px;
		transition: left 0.15s ease, transform 0.15s ease;
		transform: translateX(0);
		width: 20px;
		z-index: 1;
	}
}

.input-credit-card {
	position: relative;

	.icon {
		position: absolute;
		right: 16px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.input-with-variables {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
	gap: 8px;
	position: relative;
  }

  .variables-boxes {
	position: absolute;  
	top: 17px;
	gap: 8px;
	font-size: 12px;
	line-height: 1.3;
	left: 178px;
	display: flex;
  }
  
  .variable-box {
	background-color: #D8E5FF;
	color: #001122;
	border: 1px solid #1452FF;
	border-radius: 27px;
	padding: 4px 7px 4px 10px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	font-size: 14px;
  }
  
  .remove-variable-btn {
	background: none;
	border: none;
	margin-left: 8px;
	cursor: pointer;
	font-size: 16px;
	color: #ff4d4f;
	font-weight: bold;
  }
  
  .input-variable-field {
	flex: 1;
	border: none;
	outline: none;
	font-size: 14px;
	min-width: 100px;
  }
  
  .input-with-variables input::placeholder {
	color: #aaa;
  }
  
  .list-variables {
	list-style-type: none;
	padding: 0;
	display: flex;
	gap: 8px;
  }
  
  .item-variable {
	background-color: #f0f0f0;
	border-radius: 20px;
	padding: 5px 10px;
	cursor: pointer;
	transition: background-color 0.2s ease;
  }
  
  .item-variable:hover {
	background-color: #e6f7ff;
  }
  
  .title {
	margin-bottom: 8px;
	font-weight: bold;
  }
  
  