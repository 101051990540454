.documents-sign {
    background-color: #D8E5FF;
    max-width: 732px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px 20px;

    @media (min-width: 992px) {
        align-items: center;
        display: flex;
        padding: 40px 48px;
        text-align: left;
    }

    .h2 {
        margin-bottom: 0;

        > span {
            color: #1452FF;
        }
    }

    .btn {
        flex: 0 0 auto;
        margin-top: 24px;

        @media (min-width: 992px) {
            margin-left: 30px;
            margin-top: 0;
        }
    }
}

.documents-selected {
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    border-top: 1px solid #D5DFE8;
    bottom: -90px;
    box-shadow: 0px -3px 12px -2px rgba(85, 102, 119, 0.10);
    justify-content: space-between;
    display: flex;
    height: 73px;
    max-width: 732px;
    opacity: 0;
    padding: 12px 20px;
    position: fixed;
    transition: bottom 0.3s ease 0.05s, opacity 0.3s ease;
    width: 100%;

    @media (max-width: 991px) {
        .dropdown {
            display: none;
        }
    }
    
    @media (min-width: 992px) {
        border: 1px solid #F5F7FB;
        box-shadow: 0 0 18px 0 rgba(108, 129, 147, 0.18);
        height: 82px;
        padding: 16px 24px;
    }

    .h3 {
        margin-bottom: 0;

        @media (max-width: 991px) {
            font-size: 15px;
        }
    }

    &.show {
        bottom: 0;
        opacity: 1;
        transition: bottom 0.3s ease, opacity 0.3s ease 0.05s;
        
        @media (min-width: 992px) {
            bottom: 20px;
        }
    }
}