.card {
    background-color: #F5F7FB;
    border-radius: 4px;

    @media (max-width: 991px) {
        .dropdown,
        .btn {
            width: 100%;
        }
    }

    &-body {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 20px;

        @media (min-width: 992px) {        
            padding: 32px;
        }
    }

    &-text {
        flex-grow: 1;
        margin-bottom: 1rem;
    }

    &-outline {
        border: 1px solid #D5DFE8;
        background: #FFF;
    }
}