.signer-info{
    display: flex;
    gap: 12px;
    align-items: center;

    .signer-role{
        display: flex;
        flex-direction: column;
        color: #54626F;
        font-size: 12px;
        line-height: 1.3;

        .form-label{
            font-weight: 500;
            font-size: 14px;
            color: #1C2C3C;
            line-height: 1.5;
            letter-spacing: -0.154px;
        }
    }
}