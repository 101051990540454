.datalist {
    background-color: #fff;
    border: 1px solid #D5DFE8;
    box-shadow: 0px 3px 12px -2px rgba(85, 102, 119, 0.12);
    border-radius: 5px;
    display: none;
    margin-top: 4px;
    padding: 16px;
    position: absolute;
    z-index: 998;

    &.show {
        display: block;
    }

    &-item {
        border-radius: 5px;
        padding: 8px;
        transition: background-color 0.15s ease;

        &:hover {
            background-color: #D8E5FF;
        }
    }
}

.datalist-signer {
    width: 544px;

    @media (max-width: 575px) {
        max-width: 100%;
    }

    .datalist-item {
        align-items: center;
        color: #54626F;
        cursor: pointer;
        display: flex;
        font-weight: 400;
        font-size: 14px;
        gap: 0.5rem;
        justify-content: space-between;
        line-height: 1.5;
    }

    .avatar {
        margin-right: 0.5rem;
    }

    .signer-name {
        color: #1C2C3C;
        flex-grow: 1;
        font-weight: 500;
    }

    .signer-email {
        @media (min-width: 576px) {
            width: 50%;
        }
    }
}