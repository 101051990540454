.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    &-item {
        align-items: center;
        display: flex;
    }

    @media (min-width: 992px) {
        display: flex;
        flex-wrap: wrap;

        &-item {
            margin-left: 9px;
        }
    }
}

.navbar-toggler {
    background-color: transparent;
    border: none;
    height: 40px;
    padding: 10px 5px;

    &:before,
    &:after {
        content: "";
    }

    &:after,
    > span {
        margin-top: 5px;
    }

    &:before,
    &:after,
    > span {
        background-color: #8C97A0;
        display: block;
        height: 3px;
        width: 30px;
    }

    @media (min-width: 992px) {
        display: none;
    }
}