.page-profile-delete {
    margin-left: auto;
    margin-right: auto;
    max-width: 490px;

    .page-subheader {
        text-align: center;
    }

    .page-opening {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: 60px;
        text-align: center;

        .icon-date {
            fill: #FFBA00;
            margin-bottom: 16px;
        }

        .btn {
            margin-top: 24px;
            width: 100%;
        }
    }

    .spinner {
        height: 74px;
        margin-bottom: 24px;
        width: 74px;
    }
}