.document {
    &-header {
        align-items: flex-start;
        color: #54626F;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        justify-content: center;
        line-height: 1.35;
        padding-left: 56px;
        position: relative;
        
        @media (min-width: 992px) {
            height: 56px;
        }

        .image {
            background-size: cover;
            left: 0;
            position: absolute;
        }

        &:hover {
            color: currentColor;
        }
    }

    &-title {
        color: #1C2C3C;
        font-weight: 500;
        margin-bottom: 4px;
        word-break: break-word;

        @media (min-width: 1200px) {
            font-size: 14px;
        }
    }

    &-signer {
        position: relative;

        @media (max-width: 991px) {
            align-items: center;
            display: flex;

            .badge {
                padding-left: 0;
                padding-right: 4px;
            }
        }

        .tooltip {
            .icon {
                fill: #fff;
                position: relative;
                top: -1px;
                vertical-align: middle;
            }
        }
    }

    &-status-processing {
        opacity: 0.5;
        pointer-events: none;
    }
}

.sign-loading {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    font-weight: 600;
    height: 100%;
    justify-content: center;
    letter-spacing: 0.35px;
    line-height: 28px;
    max-width: 544px;
    text-align: center;

    .progress {
        margin-bottom: 34px;
    }
}