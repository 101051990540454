.progress {
    transform-origin: center;
    
    circle {
        fill: none;
    }

    .line {
        stroke: #1452FF;
        stroke-opacity: 0.15;
    }

    .dash {
        transition: stroke-dasharray 1s linear;
        stroke: #1452FF;
        stroke-dashoffset: 0;
    }
}