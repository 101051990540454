.container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    &,
    &-fluid {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media (min-width: 1200px) {
        max-width: 1168px;
    }
}