.sign {
    background-color: #fff;
    height: 100vh;
    padding-bottom: 64px;
    padding-top: 64px;
    position: relative;
    z-index: 0;

    .navbar {
        align-items: center;
        background-color: #fff;
        border-bottom: 1px solid #D5DFE8;
        display: flex;
        height: 64px;
        justify-content: space-between;
        margin-left: auto;
        padding-left: 32px;
        padding-right: 32px;
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1000;

        &-pending-actions {
            display: none;

            @media (min-width: 992px) {
                display: block;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
            }
        }
    }

    .btn-navbar {
        background-color: transparent;
        border: none;
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;

        > span {
            background-color: #8C97A0;
            display: block;
            height: 3px;
            width: 26px;
        }
    }

    .logo {
        @media (min-width: 992px) {
            >img {
                height: 32px;
            }
        }
    }

    .actions-total {
        align-items: center;
        background-color: #246BFF;
        border-radius: 50%;
        color: #fff;
        display: inline-flex;
        font-weight: 500;
        height: 24px;
        justify-content: center;
        margin-left: 6px;
        line-height: 24px;
        text-align: center;
        vertical-align: middle;
        width: 24px;

        &.checked {
            background-color: #00AC47;
        }
    }

    #navbar {
        display: none;
    }

    &-footer {
        align-items: center;
        background-color: #fff;
        border-top: 1px solid #D5DFE8;
        bottom: 0;
        box-shadow: 0px -3px 12px -2px rgba(85, 102, 119, 0.1);
        display: flex;
        flex: 0 0 64px;
        font-weight: 500;
        gap: 16px;
        height: 64px;
        justify-content: space-between;
        left: 0;
        line-height: 1.35;
        padding-left: 24px;
        padding-right: 24px;
        position: fixed;
        width: 100%;
    }

    @media (max-width: 991px) {

        #navbar,
        .navbar-open {
            width: 289px;
        }

        #navbar {
            background-color: #fff;
            bottom: 0;
            flex-direction: column;
            justify-content: space-between;
            overflow-y: auto;
            padding: 14px;
            position: fixed;
            right: 0;
            top: 64px;

            .btn {
                color: #384756;
                font-size: 16px;
                padding-left: 12px;
                padding-right: 12px;
                text-align: left;
                text-transform: uppercase;
                width: 100%;

                .icon {
                    fill: #8C97A0;
                }
            }

        }

        .navbar-open {
            &:before {
                background-color: #333;
                content: "";
                height: 100vh;
                left: calc((100vw - 289px) * -1);
                pointer-events: none;
                position: absolute;
                top: 0;
                width: calc(100vw - 289px);
                z-index: -1;
            }

            #navbar {
                display: flex;
            }
        }

        .navbar-footer {
            color: #707D88;
            font-size: 12px;
            line-height: 1.35;
            margin-top: 1rem;
            text-align: center;
        }

        &-footer {
            > :first-child {
                flex-grow: 1;
            }
        }
    }

    @media (min-width: 992px) {
        padding-bottom: 0;
        padding-top: 72px;

        .navbar {
            height: 72px;
        }

        .btn-navbar {
            display: none;
        }

        #navbar {
            display: block;
        }

        &-footer {
            display: none;
        }
    }
}

.sign-response {
    .icon-failed {
        fill: #EA4335;
    }

    .icon-check {
        fill: #1BD185;
    }

    .viewport {
        background-color: #fff;
        padding-bottom: 40px;
        padding-top: 40px;
        text-align: center;

        @media (max-width: 991px) {
            >.container {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .response {
        &-icon {
            margin-bottom: 24px;
        }

        &-opening {
            color: #54626F;
            line-height: 1.35;
            margin: 0 auto 40px;
            max-width: 544px;

            @media (min-width: 992px) {
                font-size: 18px;
                margin-bottom: 56px;
            }
        }
    }

    @media (max-width: 991px) {
        padding-bottom: 0;
    }

    @media (min-width: 992px) {
        .viewport {
            padding-bottom: 56px;
            padding-top: 56px;
        }

        .response {
            &-icon {
                height: 72px;
                width: 72px;
            }
        }
    }
}

.sign-documents {
    .viewport {
        background-color: transparent;

        @media (max-width: 991px) {
            >.container {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .h1 {
        margin-bottom: 1.75rem;
    }

    .document-header {
        @media (max-width: 991px) {
            padding-left: 0;

            .image {
                display: none;
            }
        }
    }
}