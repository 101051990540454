.workspace-header {
    align-items: center;
    color: #8C97A0;
    cursor: pointer;
    display: flex;
    line-height: 1.35;
    min-height: 40px;
    padding-left: 56px;
    position: relative;

    .avatar {
        left: 0;
        position: absolute;
    }

    .workspace-title {
        color: #1C2C3C;
        font-weight: 500;
    }

    &:hover {
        color: currentColor;
    }
}

.section-api-key {
    .form {
        max-width: 650px;
    }
}