.section {
    // display: flex;
    // flex-direction: column;
    // flex-grow: 1;
    margin-bottom: 2rem;
    // padding: 28px 20px 32px;

    @media (min-width: 992px) {
        margin-bottom: 3rem;
    }

    &-header {
        margin-bottom: 20px;

        @media (min-width: 768px) {
            align-items: center;
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        @media (min-width: 992px) {
            margin-bottom: 2rem;
        }

        > div {
            margin-bottom: 1rem;

            @media (min-width: 768px) {
                flex-grow: 1;
                margin-bottom: 0;
            }
        }

        .section-opening {
            margin-bottom: 0;
        }

        .section-example {
            margin-top: 8px;
            font-size: 14px;
            color: #54626F;
            font-weight: 500;

            .strong{
                color: #001122;
            }
        }
    }

    &-title {
        border-bottom: 1px solid #D5DFE8;
        padding-bottom: 1rem;

        @at-root #{&}:not(h2) {
            border-bottom: none;
            margin-bottom: 0.25rem;
            padding-bottom: 0;

            + .section-opening {
                font-size: 14px;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-opening {
        color: #54626F;
        margin-bottom: 2rem;

        *:last-child,
        &:last-child {
            margin-bottom: 0;
        }
    }
}