.spinner {
    animation: spin-rotate 2s linear infinite;
    transform-origin: center;
    width: 24px;
    
    circle {
        fill: none;
        stroke-width: 5;
    }

    .line {
        stroke: #1452FF;
        stroke-opacity: 0.15;
    }

    .dash {
        animation: spin-dash 1.5s ease-in-out infinite;
        stroke: #1452FF;
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
}
    
@keyframes spin-rotate {
    100% {
        transform: rotate(360deg);
    }
}
    
@keyframes spin-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 80, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dashoffset: -124px;
    }
}