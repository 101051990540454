.toast {
    $self: &;
    
    align-items: center;
    animation: toastEffect 0.15s ease-in-out forwards;
    background-color: #001122;
    border-radius: 4px;
    bottom: 80px;
    color: #fff;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    left: 20px;
    line-height: 1.375;
    padding: 19px 14px;
    position: fixed;
    z-index: 1030;

    @media (max-width: 991px) {
        right: 20px;
    }

    @media (min-width: 992px) {
        bottom: 12px;
        left: 50%;
        transform: translateX(-50%);
    }

    .icon {
        fill: currentColor;
        flex: 0 0 auto;
    }

    .btn {
        border: none;
        color: #fff;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        padding: 0;
        text-transform: uppercase;
    }

    &-body {
        align-items: center;
        display: flex;
        flex-grow: 1;
        margin-left: 12px;
        margin-right: 72px;
        white-space: nowrap;
        word-break: break-word;

        &:first-child {
            margin-left: 0;
        }
    }

    &-success {
        .icon {
            color: #00832D;
        }
    }
}

@keyframes toastEffect {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}