.create-free-account {
    background-color: #1452FF;
    border-radius: 5px;
    color: #F5F7FB;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    max-width: 732px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding: 40px 18px 162px;
    position: relative;

    span {
        color: #C8D40B;
    }

    .h1 {
        color: #fff;
        margin-bottom: 10px;
    }

    .btn {
        width: 100%;
    }

    .btn-group {
        margin-bottom: 32px;
        margin-top: 32px;
    }

    &-img {
        bottom: -20px;
        box-shadow: 0px 2.90541px 4.84234px -1.93694px rgba(85, 102, 119, 0.12);
        filter: drop-shadow(0px 0px 34.8649px rgba(85, 102, 119, 0.06));
        position: absolute;
        right: 50%;
        transform: translateX(50%);
    }

    @media (min-width: 992px) {
        height: 242px;
        padding: 40px 0 40px 40px;
        text-align: left;

        .btn {
            width: auto;
        }

        .btn-group {
            margin-top: 24px;
        }

        &-body {
            flex-grow: 1;
            padding-right: 38px;
            max-width: 433px;
        }

        &-img {
            right: -40px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}