.image {
    background-color: #fff;
    border: 1px solid #D5DFE8;
    border-radius: 2px;
    overflow: hidden;

    > img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}