.badge {
    border-radius: 12px;
    display: inline-flex;
    gap: 5px;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.16667;
    padding: 5px 8px;
    transition: background-color 0.15s ease, color 0.15s ease;

    .icon {
        flex: 0 0 14px;
        pointer-events: none;
    }

    &-primary {
        background-color: #1452FF;
        color: #fff;

        .icon {
            fill: currentColor;
        }
    }

    &-secondary {
        background-color: #F9F9F9;
        color: #707D88;

        .icon {
            fill: currentColor;
        }

        &.active {
            background-color: #1452FF;
            color: #fff;
        }
    }

    &-list {
        align-items: center;
        display: flex;
        gap: 8px;

        @media (max-width: 991px) {
            overflow-x: auto;
            padding: 0 20px 20px;

            &-wrapper {
                height: 24px;
                margin-left: -20px;
                margin-right: -20px;
                overflow: hidden;
            }
        }
    }
}