.form-sign {
    .form {
        $self: &;
    
        &-group {
            &-social {
                display: grid;
                gap: 16px;
                margin-bottom: 16px;
                text-align: center;
    
                &:after {
                    color: #54626F;
                    content: "ou";
                }
            }
        }

        &-help {
            color: #54626F;
        }
    }
}