.icon {
    fill: currentColor;
    vertical-align: top;

    &-processing {
        fill: #59AEE1;
    }

    &-contrast {
        fill: #8C97A0;
    }

    &-loading {
        fill: #FFBA00;
    }

    &-certificating {
        fill: #8C97A0;
    }

    &-expired {
        fill: #EA4335;
    }

    &-signed {
        fill: #00832D;
    }

    &-denied {
        fill: #EA4335;
    }

    &-canceled {
        fill: #EA4335;
    }

    &-failed {
        fill: #FFBA00;
    }
}