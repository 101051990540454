.nav-tabs {
    border-bottom: 1px solid #D5DFE8;
    display: flex;
    gap: 12px;

    @media (min-width: 992px) {
        gap: 20px;
    }

    .nav-link {
        background: none;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.35;
        padding: 7px 0 8px;
        position: relative;

        &::before {
            bottom: -1px;
            content: "";
            display: block;
            height: 3px;
            left: 0;
            position: absolute;
            width: 100%;
        }

        @media (min-width: 992px) {
            padding: 13px 0;
        }

        &:hover,
        &:focus {
            isolation: isolate;
        }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
        &::before {
            background-color: #246BFF;
        }
    }

    .dropdown-menu {
        margin-top: 1px;
    }
}

.tab-content {
    padding-top: 24px;

    @media (min-width: 992px) {   
        padding-top: 32px;
    }

    > .tab-pane {
        display: none;
    }

    > .active {
        display: block;
    }
}

.modal {
    .tab-content {
        padding-top: 20px;
    }
}