.form {
    $self: &;

    @mixin label {
        ~ #{$self}-label {
            border-radius: 2px;
            left: 11px;
            margin-bottom: 0;
            padding: 1px 5px;
            pointer-events: none;
            position: absolute;
            top: 24px;
            transform: translateY(-50%);
            transition: top 0.15s ease, font-size 0.15s ease;
        }

        &-lg {
            ~ #{$self}-label {
                top: 28px;
            }
        }
    }

    @mixin labelActive {
        ~ #{$self}-label {
            color: #142573;
            font-size: 11px;
            font-weight: 500;
            line-height: 12px;
            top: 0;
            z-index: 0;

            &::before {
                background-color: #fff;
                content: "";
                height: 1px;
                left: 0;
                position: absolute;
                top: 50%;
                width: 100%;
                z-index: -1;
            }
        }
    }

    &-group {
        margin-bottom: 1rem;
        position: relative;

        .btn-action {
            right: 1px;
            position: absolute;
            top: 1px;

            @media (max-width: 991px) {
                padding: 7px 10px;
            }

            @media (min-width: 992px) {
                top: 24px;
                transform: translateY(-50%);
            }
        }
    }

    &-label {
        color: #54626F;
        margin-bottom: 0.25rem;
    }

    &-control {
        background-color: #fff;
        border: 1px solid #D5DFE8;
        border-radius: 5px;
        color: #1C2C3C;
        display: block;
        font-size: 1rem;
        height: 40px;
        line-height: 1.25;
        padding: 9px 10px;
        transition: padding 0.1s ease, border-color 0.15s ease;
        width: 100%;

        @media (min-width: 992px) {
            height: 48px;
            line-height: 1.5;
            padding: 11px 15px;
        }

        &::placeholder {
            color: #8C97A0;
            font-size: 14px;
        }

        &:not(select) {
            &:focus,
            &:not(:placeholder-shown) {
                &[placeholder=" "] {
                    padding-bottom: 9px;
                    padding-top: 13px;
                }

                @include labelActive;            
            }
        }

        &.filled {
            padding-bottom: 9px;
            padding-top: 13px;

            @include labelActive;
        }

        &:focus {
            border-color: #246BFF;
            outline: none;
        }

        &:not(select):read-only,
        &[readonly] {
            background-color: #F5F7FB;
            pointer-events: none;
        }

        &:disabled,
        &.disabled {
            background-color: #F5F7FB;
            user-select: none;

            &,
            ~ #{$self}-label {
                color: #A8B2B8;
            }
        }

        @include label;

        &-lg {
            height: 56px;
            padding: 15px;

            &:not(select) {
                &:focus,
                &:not(:placeholder-shown) {
                    &[placeholder=" "] {
                        padding-bottom: 13px;
                        padding-top: 17px;
                    }
                }
            }

            &.filled {
                padding-bottom: 13px;
                padding-top: 17px;
            }

            ~ .btn-action {
                background-color: transparent;
                top: 28px;
            }
        }
    }

    &-check {
        color: #54626F;
        cursor: pointer;
        display: block;
        font-size: 14px;
        line-height: 18px;
        padding-left: 26px;
        user-select: none;

        a {
            display: inline-block;
        }

        &-input {
            cursor: pointer;
            float: left;
            margin-left: -26px;
        }
    }

    &-text {
        color: #54626F;
        display: block;
        font-size: 12px;
        font-weight: normal;
        line-height: 1.35;
        margin-top: 6px;
    }

    &-footer {
        align-items: flex-start;
        column-gap: 1rem;
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        row-gap: 1rem;

        @media (max-width: 991px) {
            .btn {
                width: 100%;
            }
        }

        @media (min-width: 992px) {
            margin-top: 40px;
            row-gap: 22px;
        }

        &:not(.flex-lg-row) {
            > * {
                width: 100%;
            }
        }

        &:empty {
            display: none;
        }
    }

    @media (min-width: 992px) {
        &-footer {
            column-gap: 1rem;
            row-gap: 28px;
        }
    }
}

.help-block {
    color: #EA4335;
    display: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.35;
    margin-top: 6px;
}

.group-calendar {
    padding-right: 40px;
    max-width: 360px;

    .btn-icon {
        color: #8C97A0;
        position: absolute;
        right: -40px;
    }
}

.group-dropzone {
    .btn-icon {
        margin-left: 10px;
    }

    .form-text {
        margin-top: 12px;
    }

    > .btn {
        margin-top: 1rem;

        @media (max-width: 991px) {
            width: 100%;
        }

        @media (min-width: 992px) {
            margin-top: 1.5rem;
        }
    }
}

.was-validated {
    .form {
        &-control,
        &-check-input {
            &:not(:focus) {
                &:invalid {
                    border-color: #EA4335;
                }
            }
        }
    }

    .help-block {
        display: block;
    }
}