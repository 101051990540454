.modal {
    display: none;
    height: 100%;
    left: 0;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1025;

    .btn-close {
        color: #8C97A0;
        position: absolute;
        right: 12px;
        top: 12px;
        z-index: 1;

        @media (min-width: 992px) {
            .icon {
                height: 26px;
                width: 26px;
            }
        }
    }

    .form {
        &-footer {
            margin-top: 0.5rem;
        }
    }

    &-dialog {
        align-items: flex-end;
        display: flex;
        min-height: 100%;
        pointer-events: none;
        position: relative;
        width: auto;
    
        .modal.fade & {
            transition: transform 0.3s ease-out;
            transform: translate(0, -50px);
        }
    
        .modal.show & {
            transform: none;
        }
    
        .modal.modal-static & {
            transform: scale(1.02);
        }
    
        @media (min-width: 576px) {
            max-width: 500px;
            margin: 0.5rem auto;
            min-height: calc(100% - 1rem);
        }

        @media (min-width: 768px) {
            align-items: flex-start;
            max-width: 704px;
        
            &-centered {
                align-items: center;
            }
        }
    }

    &-content {
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 10px 10px 0 0;
        box-shadow: 0px 3px 12px -2px rgba(85, 102, 119, 0.12);
        color: #54626F;
        display: flex;
        flex-direction: column;
        pointer-events: auto;
        position: relative;
        outline: 0;
        width: 100%;

        > .modal-body {
            &:first-child {
                padding-top: 32px;
            }
        }

        @media (min-width: 768px) {
            border-radius: 5px;
        }

        @media (min-width: 992px) {
            > .modal-body {
                &:first-child {
                    padding-top: 40px;
                }
            }
        }
    }

    &-icon {
        display: block;
        margin: 0 auto 1rem;
    }

    &-title {
        color: #1C2C3C;
        line-height: 1.5;
        margin-bottom: 0.625rem;

        @media (min-width: 992px) {
            margin-bottom: 0.75rem;
        }
    }

    &-header {
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 36px 20px 0;

        @media (min-width: 992px) {
            padding: 40px 40px 0;
    
            &:last-child {
                padding-bottom: 40px;
            }
        }

        *:not(.modal-title):last-child {
            margin-bottom: 0;
        }
    }

    &-body {
        flex: 1 1 auto;
        padding: 20px;
        position: relative;

        > *:last-child {
            margin-bottom: 0;
        }

        + .modal-footer {
            padding-top: 0;
        }

        @media (min-width: 992px) {
            padding: 24px 40px 40px;
        }
    }

    &-footer {
        align-items: center;
        color: #1C2C3C;
        display: flex;
        flex-shrink: 0;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: flex-end;
        padding: 20px;

        @media (max-width: 767px) {
            .btn {
                width: 100%;
            }
        }

        @media (min-width: 992px) {
            padding: 40px;
        }
    }

    &-backdrop {
        background-color: #001122;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1020;
        width: 100vw;
        height: 100vh;
    
        &.fade {
            opacity: 0;
        }

        &.show {
            opacity: 0.8;
        }
    }
}

.modal-alert.text-center {
    .modal {
        &-footer {
            justify-content: center;
        }
    }
}

.modal-danger {
    .modal {
        &-icon {
            color: #EA4335;
        }
    }
}

.modal-warning {
    .modal {
        &-icon {
            color: #FFBA00;
        }
    }
}

.modal-api-key-view {
    @media (min-width: 992px) {
        .modal-dialog {
            max-width: 804px;
        }
    }

    .modal-body {
        display: flex;
        gap: 1rem;

        .btn {
            white-space: nowrap;
        }
    }
}

.modal-contacts {
    .tab-pane {
        max-height: 443px;
        overflow-y: auto;
    }

    .pagination-spinner {
        display: block;
        margin: 1rem auto;
    }

    .signer-checked,
    .signer-selected {
        background-color: #EBF1FF;
    }

    .signer-selected {
        opacity: 0.5;
        pointer-events: none;
    }

    .table tr {
        cursor: pointer;
    }

    .modal {
        // &-scroll {
        //     max-height: 443px;
        // }

        &-footer {
            justify-content: space-between;
        }
    }
}

.modal-viewer {
    .spinner {
        display: block;
        margin: 0 auto;
    }
}