.page-loader {
    align-items: center;
    background-color: rgba(0, 17, 34, 0.8);
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 500;
    height: 100vh;
    justify-content: center;
    left: 0;
    line-height: 1.35;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1030;

    .card-body {
        align-items: center;
        text-align: center;
    }

    .spinner {
        height: 80px;
        margin-bottom: 16px;
        width: 80px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}