.form-sign-verify {
    a {
        font-weight: bold;
    }

    .btn-request {
        border: none;
        font-size: 16px;
        padding: 0;
    }

    .form {
        &-group {
            display: flex;
            column-gap: 8px;
        }

        &-control {
            font-weight: bold;
            flex-grow: 1;
            text-align: center;
        }

        &-text {
            display: none;
        }

        &-footer {
            gap: 16px;
            margin-bottom: 48px;
        }

        @media (min-width: 576px) {
            &-group {
                column-gap: 16px;
            }

            &-control {
                flex-grow: 0;
                width: 56px;
            }
        }
    
        @media (min-width: 992px) {
            &-footer {
                display: flex;

                [type=submit] {
                    flex-grow: 1;
                }
            }
        }
    }
}