.pagination {
    align-items: center;
    display: flex;
    gap: 6px;
    justify-content: center;
    margin-bottom: 20px;

    @media (min-width: 992px) {
        justify-content: flex-end;
    }
}

.page {
    &-item {
        border-radius: 3px;
        color: #707D88;
        display: block;
        font-size: 14px;
        font-weight: 500;
        height: 32px;
        line-height: 32px;
        text-align: center;
        transition: background-color 0.15s ease, color 0.15s ease;
        width: 32px;

        .icon {
            position: relative;
            top: -1px;
            vertical-align: middle;
        }

        &.active,
        &:hover {
            background-color: #1452FF;
            color: #fff;
            font-weight: 600;
        }

        &.active {
            pointer-events: none;
        }
    }
}