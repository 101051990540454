.colorpicker {
    left: 100%;
    margin-left: 34px;
    position: absolute;
    z-index: 2;

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%); 
    }

    &:before {
        border: 9px solid transparent;
        border-left: none;
        border-right-color: rgba(0, 0, 0, 0.15);
        left: -9px;
    }

    &:after {
        border: 8px solid transparent;
        border-left: none;
        border-right-color: #fff;
        left: -8px;
    }

    &-toggle {
        align-items: center;
        display: flex;
        padding-left: 0;
        padding-right: 0;
        position: relative;

        &.disabled {
            background-color: transparent;
            border: none;
            color: currentColor;
        }
    }

    &-color {
        border: 1px solid #D5DFE8;
        border-radius: 50%;
        height: 32px;
        margin-right: 10px;
        width: 32px;
    }

    &-overlay {
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1;
    }
}