.section-workspace {
    margin-bottom: 1.5rem;

    .section-header {
        margin-bottom: 1.25rem;
    }

    & + & {
        border-top: 1px solid #D5DFE8;
        padding-top: 1.5rem;
    }
}

.theme-preview {
    display: none;

    @media (min-width: 992px) {
        background-color: #F5F7FB;
        border: 1px solid #D5DFE8;
        border-radius: 4px;
        display: block;
        padding: 25px 24px 32px;
        text-align: center;

        > svg {
            max-width: 100%;
        }

        &-header {
            align-items: center;
            display: flex;
            font-size: 14px;
            gap: 6px;
            justify-content: center;
            line-height: 1.5;
            margin-bottom: 20px;

            b {
                font-weight: bold;
                margin-right: 4px;
            }

            .btn {
                border-radius: 12px;
                font-weight: 500;
                line-height: 16px;
                padding: 3px 8px;

                .icon {
                    min-height: 16px;
                }

                &.active {
                    background-color: #1452FF;
                    color: #fff;
                }
            }
        }
    }
}

.user-header {
    align-items: center;
    display: flex;
    line-height: 1.35;

    .avatar {
        margin-right: 0.5rem;

        @media (min-width: 992px) {  
            margin-right: 1.5rem;
        }
    }

    .user-title {
        color: #1C2C3C;
        font-weight: 500;
    }
}

.field-header {
    align-items: center;
    color: #8C97A0;
    display: flex;
    line-height: 1.35;
    min-height: 24px;
    padding-left: 32px;
    position: relative;

    @media (min-width: 992px) {
        padding-left: 40px;
    }

    .icon {
        left: 0;
        position: absolute;
    }

    .field-title {
        color: #1C2C3C;
        font-weight: 500;
    }

    &:hover {
        color: currentColor;
    }
}

.text-accepted-files {
    cursor: pointer;
    position: relative;
}

.webhook-list {
    border-bottom: 1px solid #D5DFE8;
    margin-bottom: 40px;
    padding-bottom: 40px;
    margin-top: 40px;
}

.webhook-item {
    align-items: center;
    color: #54626F;
    display: flex;
    font-size: 14px;
    justify-content: space-between;

    & + & {
        margin-top: 1rem;
    }

    &-title {
        color: #1C2C3C;
        display: block;
        font-weight: 600;
    }

    .control-switch {
        margin-left: 24px;
    }
}

.webhook-updated_at {
    .h3 {
        margin-bottom: 0.75rem;
    }

    color: #54626F;
}