@import '../../styles/mixins/mobile';

.page {
    min-height: 100vh;
    padding-bottom: 50px;
    padding-top: 80px;

    @media (min-width: 992px) {
        padding-top: 104px;
    }

    &-header {
        @include header;

        background-color: #F9F9F9;
        color: #54626F;

        @media (min-width: 992px) {
            height: 72px;
            padding-bottom: 12px;
            padding-top: 12px;
        }
    
        > .container,
        > .container-fluid {
            align-items: center;
            display: flex;
            height: 100%;
            justify-content: space-between;
        }
    
        .nav {
            .btn {
                margin-left: 1rem;
            }
        }
    
        @media (max-width: 575px) {
            .nav {
                display: none;
            }
        }
    }

    &-subheader {
        margin-bottom: 32px;
    }

    &-opening {
        color: #54626F;
    }

    @media (min-width: 576px) {
        padding-bottom: 60px;
        padding-top: 132px;
    }
}

.page-sign {
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .page {
        &-footer {
            display: grid;
            font-weight: 500;
            padding-top: 44px;
        }
    }

    @media (min-width: 576px) {
        .page {
            &-footer {
                display: none;
            }
        }
    }

    @media (min-width: 768px) {
        max-width: 496px;
    }
}