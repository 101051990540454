.viewport {
    align-items: center;
    background-color: #F5F7FB;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 24px;
    scroll-behavior: smooth;

    .alert {
        margin-top: -24px;
        width: calc(100% + 48px);
    }

    &-content {
        align-items: center;
        display: flex;
        flex-direction: column;
        transform: scale(var(--canvas-scale));
        transform-origin: 50% 0;
        width: calc(var(--canvas-width) / var(--canvas-scale));
    }

    &-footer {
        align-items: center;
        color: #707D88;
        display: flex;
        flex: 0 0 58px;
        font-size: 12px;
        height: calc(58px / var(--canvas-scale));
        gap: 4px;
        justify-content: center;
        padding-bottom: 10px;
        transform: scale(calc(1 + var(--canvas-scale)));
    }
}